class ODataQueryBuilder {
  constructor(baseUrl, entitySet, headers) {
    this.baseUrl = baseUrl;
    this.entitySet = entitySet;
    this.headers = headers;
  }

  getMetadataUrl() {
    const url = `${this.baseUrl}/${this.entitySet}/metadata`;

    return url;
  }

  buildUrl(options) {
    let url = `${this.baseUrl}/${this.entitySet}`;

    const {
      itemsPerPage = 10, page = 0, sortDesc = 0, sortBy = [], filter = null, search = '',
    } = options;
    if (itemsPerPage) {
      url += `?$top=${itemsPerPage}`;
    }
    if (page) {
      url += `&$skip=${itemsPerPage * (page - 1)}`;
    }

    if (filter?.length > 1) {
      url += `&$filter=${filter}`;
    }

    if (sortBy.length > 0) {
      // const orderBy = orderby
      //   .map((item) => (item.direction ? `${item.field} ${item.direction}` : item.field))
      //   .join(',');

      url += `&$orderby=${sortBy.join(',')} ${sortDesc ? 'desc' : 'asc'}`;
    }

    if (search?.length > 1) {
      const searchFields = this.headers
        .filter((header) => header.searchable)
        .map((header) => `contains(${header.value}, '${search}')`);
      url += `&$filter=${searchFields.join(' OR ')}`;
    }

    url += '&$count=true';

    return url;
  }
}

export default ODataQueryBuilder;
