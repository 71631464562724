<template>
  <v-data-table
    hide-default-header
    class="server-table"
    :headers="localColumns"
    :items="filteredItems"
    :loading="isLoading"
    :options.sync="dataOptions"
    :server-items-length="count"
    dense
    @click:row="rowClick"
  >
    <template #top>
      <div class="d-flex align-stretch pa-2">
        <slot name="top"></slot>
        <v-spacer />
        <v-text-field v-model="dataOptions.search" class="mt-4" :append-icon="mdiMagnify" label="Search" dense />
        <v-btn class="mt-2" icon title="Refresh data" @click="refresh">
          <v-icon>{{ mdiRefresh }}</v-icon>
        </v-btn>
        <ColumnDialog :headers.sync="localColumns" :all-headers="allHeaders" />
        <FiltersDialog
          :headers.sync="localColumns"
          :all-headers="allHeaders"
          :metadata="metadata"
          :filters.sync="filters"
        />
        <DownloadData :entity-set="entitySet" :file-extension="fileExtension" />
        <UploadData :entity-set="entitySet" />
      </div>
    </template>
    <template #header="{ props }">
      <thead class="v-data-table-header mt-1">
        <tr>
          <th v-for="column in props.headers" :key="column.value" class="text-start sortable" :width="column.width">
            {{ column.text }}
          </th>
        </tr>
      </thead>
    </template>
    <template v-for="slot in slots" #[`item.${slot.name}`]="{ item, index }">
      <slot :name="slot.name" :item="item" :index="index"></slot>
    </template>
  </v-data-table>
</template>

<script>
import {
  mdiMagnify, mdiMenu, mdiClose, mdiRefresh,
} from '@mdi/js';
import { mapState } from 'vuex';
import { Client } from '@codehq/aurora-net';
import ODataQueryBuilder from './ODataQueryBuilder';
import ColumnDialog from './ColumnDialog.vue';
import FiltersDialog from './FiltersDialog.vue';
import DownloadData from './DownloadData.vue';
import UploadData from './UploadData.vue';

const baseUrl = process.env.VUE_APP_APIURL;

export default {
  name: 'ServerDataTable',
  components: {
    ColumnDialog,
    DownloadData,
    FiltersDialog,
    UploadData,
  },
  props: {
    allHeaders: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
    hideTemplate: {
      type: Boolean,
      default: false,
    },
    entitySet: {
      type: String,
      required: true,
    },
    slots: {
      type: Array,
      default: () => [],
    },
    fileExtension: {
      type: String,
      default: 'xlsx',
    },
  },
  data() {
    return {
      mdiClose,
      mdiMagnify,
      mdiMenu,
      mdiRefresh,
      count: 0,
      items: [],
      isLoading: false,
      metadata: [],
      filters: [],
      localColumns: this.headers ?? [],
      dataOptions: {
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: false,
        page: 1,
        filter: 'IsDeleted eq false',
        search: '',
      },
    };
  },
  computed: {
    ...mapState('organizations', ['organization']),
    client() {
      return new Client({
        baseUrl: process.env.VUE_APP_APIURL,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        apiClient: 'axios',
        timeout: 30000,
        headers: {
          organizationid: this.organization.id,
        },
      });
    },
    filteredItems() {
      return this.items;
    },
    queryBuilder() {
      return new ODataQueryBuilder(`${baseUrl}/odata`, this.entitySet, this.localColumns);
    },
  },
  watch: {
    headers: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.localColumns = newValue;
      },
    },
    dataOptions: {
      immediate: true,
      deep: true,
      handler() {
        this.refresh();
      },
    },
    localColumns: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('update:headers', newValue);
      },
    },
    filters: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.dataOptions.filter = newValue.map(f => `${f.query()}`).join(' and ');
        this.refresh();
      },
    },
  },
  async created() {
    const url = this.queryBuilder.getMetadataUrl();
    const { data } = await this.client.get(url);
    console.log(data);
    this.metadata = data.value;
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      const { queryBuilder } = this;
      const url = queryBuilder.buildUrl({ ...this.dataOptions });
      this.isLoading = true;
      const { data } = await this.client.get(url);
      this.items = data.value;
      this.count = data['@odata.count'];
      this.isLoading = false;
    },
    rowClick(item) {
      this.$emit('row:click', item);
    },
  },
};
</script>
