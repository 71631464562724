import { sortBy, uniq } from 'lodash';

export default {
  getVenue: (state) => state.venue,
  venueDimensions: (state) => {
    const { venues } = state;
    const dimensions = venues.map((venue) => ({
      width: venue.width,
      height: venue.height,
      ratio: venue.width / venue.height,
      text: `${venue.width}x${venue.height}`,
      value: `${venue.width}x${venue.height}`,
      name: `${venue.width}x${venue.height}`,
      type: 'dimension',
    }));

    return sortBy(uniq(dimensions), 'width').reverse();
  },
};
