<template>
  <div>
    <v-btn class="mt-2" icon title="Upload data to Excel" :loading="isLoading" @click="openFileDialog">
      <v-icon>{{ mdiUpload }}</v-icon>
    </v-btn>
    <input ref="fileInput" type="file" style="display:none" @change="upload">
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Client } from '@codehq/aurora-net';
import { mdiUpload, mdiDownload } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'UploadData',
  mixins: [loading],
  props: {
    entitySet: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiUpload,
      mdiDownload,
    };
  },
  computed: {
    ...mapState('organizations', ['organization']),
    ...mapState('auth', ['token']),
    client() {
      return new Client({
        baseUrl: process.env.VUE_APP_APIURL,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        apiClient: 'axios',
        timeout: 0,
        headers: {
          organizationid: this.organization.id,
        },
      });
    },
  },
  methods: {
    async openFileDialog() {
      this.$refs.fileInput.click();
    },
    async upload(event) {
      const file = event.target.files[0];
      console.debug(file);
      const uploadUrl = `${process.env.VUE_APP_APIURL}/odata/${this.entitySet}/upload`;
      const formData = new FormData();
      formData.append('file', file);
      try {
        this.isLoading = true;
        const { client } = this;
        client.headers['Content-Type'] = 'multipart/form-data';
        await client.post(uploadUrl, formData);
        alert('File uploaded successfully!');
      } catch (error) {
        console.error(error);
        alert('Error uploading file!');
      } finally {
        this.isLoading = false;
      }
    },
    async download() {
      const downloadUrl = `${process.env.VUE_APP_APIURL}/odata/${this.entitySet}/download`;
      try {
        const response = await this.client.get(downloadUrl, { responseType: 'blob' });
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.name}.xlsx`;
        link.click();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
