import 'core-js/stable';
import '@/styles/styles.scss';

import axios from 'axios';
import VueAxios from 'vue-axios';
import auroraModule from '@codehq/aurora-app-core/src/plugins/auroraModule';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueMask from 'v-mask';
import VuetifyConfirm from 'vuetify-confirm';
import { Auth, AuthPlugin } from '@codehq/aurora-auth';
import { Client, ClientPlugin } from '@codehq/aurora-net';
import Vue from 'vue';
import KeyTextField from './components/KeyTextField.vue';
import ServerDataTable from './views/DataTables/ServerDataTable.vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import { i18n } from './i18n';

Vue.config.productionTip = false;

Vue.use(auroraModule, {
  baseUrl: process.env.VUE_APP_APIURL,
  store,
});

// register global components
Vue.use(AuthPlugin, {
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
Vue.use(ClientPlugin, {
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  apiClient: 'axios',
  timeout: 30000,
});
console.debug(AuthPlugin);
Vue.use(VueAxios, axios);

Vue.component('KeyTextField', KeyTextField);
Vue.component('ServerDataTable', ServerDataTable);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places,drawing,visualization',
  },
  installComponents: true,
});
Vue.use(VueMask);
Vue.use(VuetifyConfirm, { vuetify });

// setup store
const client = new Client({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
  apiClient: 'axios',
  timeout: 30000,
});
store.$http = client;

const auth = new Auth({
  baseUrl: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
store.$auth = auth;

// Setup API
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

console.debug(store);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
