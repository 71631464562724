<template>
  <div />
</template>

<script>
/* eslint-disable indent */
import axios from 'axios';
import { authHub } from '@codehq/aurora-app-core/src';
import { useAuth } from '@/use';

export default {
  name: 'Auth',
  computed: {
    organization() {
      return this.$store.getters['organizations/organization'];
    },
  },
  async created() {
    this.$log.debug('setting up listeners');
    this.$log.debug('setting up axios');
    axios.interceptors.request.use(
      async (config) => {
        const token = await useAuth.getAccessToken();
        const { organization } = this;
        const result = { ...config };
        if (token) {
          result.headers.Authorization = `Bearer ${token}`;
        }
        if (organization) {
          result.headers.organizationid = `${organization?.id}`;
        }

        return config;
      },
      (error) => Promise.reject(error),
    );
    axios.interceptors.response.use(
      response => response,
      async error => {
        console.error(error);
        if (error) {
          if (error?.response?.status === 401) {
            this.$store.dispatch('auth/LOGOUT_auth');
            this.$router.push({ name: 'auth-login' });
            this.$log.warn('unauthorized');
          } else {
            this.$log.error(error);
          }
        } else {
          this.$log.error('Error not found');
        }

        return Promise.reject(error);
      },
    );
    authHub.$on('network:unauthorized', async () => {
      this.$store.dispatch('auth/LOGOUT_auth');
      this.$router.push({ name: 'auth-login' });
      this.$log.warn('unauthorized');
    });
  },
  destroyed() {
    this.$log.debug('destroying listeners');
    authHub.$off('network:unauthorized');
  },
};
</script>
