<template>
  <v-dialog v-model="filtersDialog" max-width="400">
    <template #activator="{ on }">
      <v-btn class="mt-2" icon title="Filters" v-on="on">
        <v-icon>{{ mdiFilter }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title color="accent">
        <span class="grey--text">Filters</span>
      </v-card-title>
      <v-card-text>
        <div>
          <!-- Field Selector -->
          <v-select
            v-model="selectedField"
            :items="fields"
            item-text="displayName"
            item-value="name"
            label="Select a field"
            @change="updateSearchOptions"
          ></v-select>

          <!-- Search Options Selector -->
          <v-select
            v-if="selectedField"
            v-model="selectedOption"
            :items="searchOptions"
            label="Select a search option"
          ></v-select>

          <!-- Filter Value Input -->
          <v-text-field
            v-if="selectedOption"
            v-model="filterValue"
            label="Enter filter value"
          ></v-text-field>

          <!-- Add Filter Button -->
          <v-btn @click="addFilter">
            Add Filter
          </v-btn>

          <!-- List of Added Filters -->
          <v-list v-if="filters.length">
            <v-list-item-group>
              <v-list-item v-for="(filter, index) in filters" :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ filter.field }} {{ filter.option }} {{ filter.value }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon @click="removeFilter(index)">
                    {{ mdiDelete }}
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="filtersDialog = false">
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiFilter, mdiClose, mdiMenu, mdiDelete,
} from '@mdi/js';

export default {
  name: 'FiltersDialog',
  props: {
    allHeaders: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    metadata: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mdiClose,
      mdiDelete,
      mdiMenu,
      mdiFilter,
      filtersDialog: false,
      localColumns: this.headers,
      selectedField: null,
      searchOptions: [],
      selectedOption: null,
      filterValue: '',
      filters: [],
      icons: {
        delete: mdiDelete,
      },
    };
  },
  computed: {
    fields() {
      return this.metadata.map(m => ({
        name: m.name,
        displayName: m.displayName,
        type: m.type,
      }));
    },
  },
  watch: {
    localColumns: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('update:headers', newValue);
      },
    },
  },
  methods: {
    saveColumnOrder() {
      this.$emit('update:headers', this.localColumns);
    },
    data() {
      return {
      };
    },

    updateSearchOptions() {
      const field = this.fields.find(f => f.name === this.selectedField);
      if (!field) return;

      switch (field.type) {
        case 'System.String':
          this.searchOptions = ['starts with', 'contains'];
          break;
        case 'System.DateTimeOffset':
        case 'System.Nullable`1[[System.DateTimeOffset, mscorlib, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089]]':
          this.searchOptions = ['before', 'after'];
          break;
        case 'System.Boolean':
          this.searchOptions = ['is true', 'is false'];
          break;
        case 'System.Int32':
        case 'System.Int64':
        case 'System.UInt32':
        case 'System.UInt64':
        case 'System.Nullable`1[[System.Int32, mscorlib, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089]]':
        case 'System.Nullable`1[[System.Int64, mscorlib, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089]]':
        case 'System.Nullable`1[[System.UInt32, mscorlib, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089]]':
        case 'System.Nullable`1[[System.UInt64, mscorlib, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089]]':
          this.searchOptions = ['equals', 'greater than', 'less than'];
          break;
        case 'System.Double':
          this.searchOptions = ['equals', 'greater than', 'less than', 'between'];
          break;
        case 'System.Decimal':
          this.searchOptions = ['equals', 'greater than', 'less than'];
          break;
        default:
          this.searchOptions = [];
      }
    },
    addFilter() {
      if (this.selectedField && this.selectedOption && this.filterValue) {
        this.filters.push({
          field: this.selectedField,
          option: this.selectedOption,
          value: this.filterValue,
        });
        this.selectedField = null;
        this.selectedOption = null;
        this.filterValue = '';
        this.$emit('update:filters', this.filters);
      }
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
    },
  },
};
</script>
