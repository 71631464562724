import axios from 'axios';

export default {
  async LOAD_points({ commit }, description) {
    const key = process.env.VUE_APP_GOOGLE_API_KEY;
    const params = new URLSearchParams({
      description,
      fields: 'photos,formatted_address,name,rating,opening_hours,geometry',
      key,
    });

    try {
      this.isLoading = true;
      const { data } = await axios.get(`/api/google-query/maps?${params.toString()}`);
      commit('SET_points', data.results);

      return data.results;
    } catch (error) {
      console.error('Error searching Google Maps:', error);

      return [];
    } finally {
      this.isLoading = false;
    }
  },
};
