import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('impression', 'impressions');

const customActions = {
  async LOAD_impressions({ rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('impressions')
      .query({
        $filter: 'IsDeleted eq false',
        $orderby: 'RowLabel',
        $select: 'Id,RowLabel,HourOfWeek,ImpressionsPerSpot',
      });

    return data;
  },
};

export default { ...actions, ...customActions };
