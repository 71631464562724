<template>
  <v-app>
    <v-main>
      <div class="auth-wrapper auth-v1">
        <div class="auth-inner">
          <v-card class="auth-card">
            <v-card-text>
              <p class="text-2xl font-weight-semibold text--primary mb-2">
                <v-icon>{{ mdiWrenchClock }}</v-icon> System maintenance
              </p>
              <p class="mb-2">
                We are currently performing an update to the system
              </p>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mdiWrenchClock } from '@mdi/js';

export default {
  name: 'LayoutUpdate',
  data() {
    return {
      mdiWrenchClock,
    };
  },
};
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
