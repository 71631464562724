import dayjs from 'dayjs';

export const useFormatting = () => {
  const formatCurrency = (value, currency = 'ZAR') => {
    const formatter = new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    });

    return formatter.format(value);
  };

  const formatDate = (date, format = 'YYYY-MM-DD') => dayjs(date).format(format);
  const formatDateTime = (date, format = 'YYYY-MM-DD HH:mm:ss') => dayjs(date).format(format);

  return {
    formatCurrency,
    formatDate,
    formatDateTime,
  };
};
