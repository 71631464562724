<template>
  <v-snackbar
    v-model="snackbar"
    app
    data-cy="snackbar-ToastError"
    :color="color"
    :timeout="timeout"
    :multi-line="false"
    centered
    top
    vertical
    transition="slide-y-transition"
  >
    <v-icon color="white">
      {{ mdiCancel }}
    </v-icon>
    {{ text }}
    <template #action="{ attrs }">
      <v-btn
        color="white"
        small
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { VSnackbar } from 'vuetify/lib';
import { mdiCancel } from '@mdi/js';

/**
 * Aurora Snackbar Notifications
 *
 */
export default {
  name: 'ToastError',
  components: {
    VSnackbar,
  },
  props: {
    /**
    * Set the mode for the snackbar message. Default: 'vertical',
    */
    mode: {
      type: String,
      default: 'vertical',
    },

    /**
    * Set the timeout for the snackbar message. Default: -1,
    */
    timeout: {
      type: Number,
      default: -1,
    },

    /**
    * Set the color for the snackbar message. Default: 'success' Should be 'success', 'danger', etc,
    */
    color: {
      type: String,
      default: 'error',
    },
  },
  data() {
    return {
      mdiCancel,
      snackbar: false,
      text: 'Application error',
    };
  },
  mounted() {
    this.$root.$on('toast:error', (text) => {
      console.debug(text);

      this.text = text;
      this.snackbar = true;
    });
  },
  destroyed() {
    this.$root.$off('toast:error');
  },
};
</script>
