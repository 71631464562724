<template>
  <v-text-field
    v-model="localValue"
    v-bind="$props"
    :disabled="isEdit"
    :rules="[noErrors, validation.rules.required]"
    :data-cy="dataCy"
    :error-messages="errors"
    v-on="$listeners"
  >
  </v-text-field>
</template>

<script>
import { debounce } from 'lodash';
import { VTextField } from 'vuetify/lib';
import ODataKeyValidator from '../ODataKeyValidator';

export default {
  name: 'KeyTextField',
  extends: VTextField,
  props: {
    dataCy: {
      type: String,
      default: 'KeyInputField',
    },
    entity: {
      type: String,
      required: true,
    },
    keyField: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value,
      errors: [],
      validator: undefined,
    };
  },
  computed: {
    noErrors() {
      if (this.errors.length > 0) {
        return false;
      }

      return true;
    },
  },
  watch: {
    localValue() {
      this.$emit('input', this.localValue);
      this.uniqueKey(this.localValue);
    },
    value() {
      this.localValue = this.value;
    },
  },
  mounted() {
    const { entity, keyField } = this;
    this.validator = new ODataKeyValidator(entity, keyField);
  },
  methods: {
    // eslint-disable-next-line func-names
    uniqueKey: debounce(async function (value) {
      if (!value) {
        this.errors = [];

        return;
      }
      if (value === '') {
        this.errors = [];

        return;
      }

      try {
        const valid = await this.validator.validate(value);
        if (!valid) {
          this.errors = ['This key is already in use.'];
        } else {
          this.errors = [];
        }
      } catch (error) {
        this.$log.error(error);
      }
    }),
  },
};
</script>
