<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-slide-x-transition>
          <v-img
            :src="require('@/assets/polygon-logo.png')"
            max-width="200px"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        v-for="menuLink in menuLinks"
        :key="menuLink.title"
        :title="menuLink.title"
        :to="menuLink.to"
        :icon="icons[menuLink.icon]"
      ></nav-menu-link>

      <nav-menu-group
        v-for="menuGroup in menuGroups"
        :key="menuGroup.title"
        :title="menuGroup.title"
        :icon="icons[menuGroup.icon]"
      >
        <nav-menu-link
          v-for="subMenuLink in menuGroup.subMenu"
          :key="subMenuLink.title"
          :title="subMenuLink.title"
          :to="subMenuLink.to"
          :target="subMenuLink.target"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-section-title title="Admin"></nav-menu-section-title>

      <nav-menu-link
        v-for="adminMenuLink in adminMenuLinks"
        :key="adminMenuLink.title"
        :title="adminMenuLink.title"
        :to="adminMenuLink.to"
        :icon="icons[adminMenuLink.icon]"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiHomeOutline,
  mdiPackageVariantClosed,
  mdiPackageVariantClosedPlus,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountMultiple,
  mdiAccountCogOutline,
  mdiBookPlayOutline,
  mdiWatermark,
  mdiCash,
} from '@mdi/js';
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue';
import NavMenuGroup from './components/NavMenuGroup.vue';
import NavMenuLink from './components/NavMenuLink.vue';

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiPackageVariantClosed,
        mdiPackageVariantClosedPlus,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiBookPlayOutline,
        mdiWatermark,
        mdiCash,
        mdiAccountMultiple,
      },
      menuLinks: [
        // List your menu links here
        { title: 'Estimations', to: { name: 'estimations-ListEstimation' }, icon: 'mdiCash' },
        { title: 'Brands', to: { name: 'brands-ListBrand' }, icon: 'mdiWatermark' },
        { title: 'Clients', to: { name: 'admin-ListClient' }, icon: 'mdiAccountMultiple' },
      ],
      menuGroups: [
        // List your menu groups here
        // {
        //   title: 'Venue',
        //   icon: 'mdiPackageVariantClosed',
        //   subMenu: [
        //     { title: 'Add Product', to: { name: 'dashboard' }, target: '_blank' }],
        // },
      ],
      adminMenuLinks: [
        // List your admin menu links here
        { title: 'Feedback Items', to: { name: 'admin-FeedbackItems' }, icon: 'mdiIconName' },
        { title: 'Impressions', to: { name: 'admin-ListImpressions' }, icon: 'mdiIconName' },
        { title: 'Share of Voice', to: { name: 'sov-ListShareOfVoiceRecommendation' }, icon: 'mdiIconName' },
        { title: 'Venues', to: { name: 'admin-ListVenue' }, icon: 'mdiIconName' },
        { title: 'Venue Types', to: { name: 'admin-ListVenueType' }, icon: 'mdiIconName' },
        { title: 'Users', to: { name: 'admin-Users' }, icon: 'mdiIconName' },
      ],
    };
  },
};
</script>
