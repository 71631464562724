<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        small
        v-bind="attrs"
        v-on="on"
        @click="open"
      >
        <v-icon>{{ mdiCommentQuoteOutline }}</v-icon>
      </v-btn>
    </template>
    <template #default="">
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          Did you find this useful or not?
        </v-toolbar>
        <v-card-text>
          <v-textarea
            v-model="message"
            label="Let us know what you think"
            textarea
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="success"
            :loading="isLoading"
            @click="send"
          >
            Send
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-img
            v-if="screenshot"
            :src="screenshot"
          />
          <v-btn
            v-else
            :loading="isLoadingScreenshot"
            @click="saveScreenShot"
          >
            Add Screenshot
          </v-btn>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mdiCommentQuoteOutline } from '@mdi/js';
import html2canvas from 'html2canvas';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import axios from 'axios';

export default {
  name: 'Feedback',
  mixins: [loading],
  data() {
    return {
      dialog: false,
      isLoadingScreenshot: false,
      message: '',
      screenshot: undefined,
      mdiCommentQuoteOutline,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.saveScreenShot();
    },
    async saveScreenShot() {
      try {
        this.isLoadingScreenshot = true;
        const screenshotTarget = document.body;
        const canvas = await html2canvas(screenshotTarget);
        const base64image = canvas.toDataURL('image/png');
        this.screenshot = base64image;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.isLoadingScreenshot = false;
      }
    },
    async send() {
      try {
        this.isLoading = true;
        const { message, screenshot } = this;
        await axios.post('odata/feedbackItems', {
          message,
          screenshot,
        });
        this.dialog = false;
        this.$root.$emit('toast:notify', 'Thank you for your feedback');
        this.message = '';
        this.screenshot = '';
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
