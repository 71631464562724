import Vue from 'vue';
import VueRouter from 'vue-router';
import { kebabCase } from 'case-anything';
import modules from '../modules';
import guards from './guards';

Vue.use(VueRouter);

const mapViews = (prefix, views) => {
  const viewsArr = Object.keys(views)
    .filter(v => views[v].name !== 'Home')
    .map(v => ({
      path: `/${prefix}/${kebabCase(v)}`,
      name: `${prefix}-${v}`,
      component: views[v],
      meta: views[v].meta,
    }));

  return viewsArr;
};

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/init',
    name: 'app-Init',
    component: () => import('@/views/Init.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/on-board',
    name: 'app-Onboard',
    component: () => import('@/views/Onboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/estimation',
    name: 'estimation',
    component: () => import('@/views/estimation/Estimation.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/estimations/edit',
    name: 'estimation-EditEstimation',
    component: () => import('@/views/estimation/EditEstimation.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/estimations',
    name: 'estimations-ListEstimation',
    component: () => import('@/views/estimation/ListEstimation.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/sov-recommendations',
    name: 'sov-ListShareOfVoiceRecommendation',
    component: () => import('@/views/estimation/ListShareOfVoiceRecommendation.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/sov-recommendations/edit',
    name: 'sov-EditShareOfVoiceRecommendation',
    component: () => import('@/views/estimation/EditShareOfVoiceRecommendation.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/users',
    name: 'admin-Users',
    component: () => import('@/views/UserManagementHome.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/register',
    name: 'auth-register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/admin/impressions',
    name: 'admin-ListImpressions',
    component: () => import('@/views/admin/Impressions.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/clients',
    name: 'admin-ListClient',
    component: () => import('@/views/admin/ListClient.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/client',
    name: 'admin-EditClient',
    component: () => import('@/views/admin/EditClient.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/brands',
    name: 'brands-ListBrand',
    component: () => import('@/views/brands/ListBrand.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/brand',
    name: 'brands-EditBrand',
    component: () => import('@/views/brands/EditBrand.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/feedback-items',
    name: 'admin-FeedbackItems',
    component: () => import('@/views/FeedbackItems.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/venues',
    name: 'admin-ListVenue',
    component: () => import('@/views/venue/ListVenue.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/venue',
    name: 'admin-EditVenue',
    component: () => import('@/views/venue/EditVenue.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/venueTypes',
    name: 'admin-ListVenueType',
    component: () => import('@/views/venue/ListVenueType.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/venueType',
    name: 'admin-EditVenueType',
    component: () => import('@/views/venue/EditVenueType.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const moduleRoutes = modules.map(m => mapViews(m.config.path, m.views)).flat();

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...routes, ...moduleRoutes],
});

router.beforeEach(guards);

export default router;
