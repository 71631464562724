import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('venue', 'venues');

const customActions = {
  async LOAD_venues({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('venues')
      .query({
        $filter: 'IsDeleted eq false',
        $expand: 'venueType',
      });
    commit('SET_venues', data);

    return data;
  },
};

export default { ...actions, ...customActions };
