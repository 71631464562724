<template>
  <v-btn class="mt-2" icon title="Download data to Excel" :loading="isLoading" @click="download">
    <v-icon>{{ mdiDownload }}</v-icon>
  </v-btn>
</template>

<script>
import { mapState } from 'vuex';
import { Client } from '@codehq/aurora-net';
import { mdiDownload } from '@mdi/js';
import axios from 'axios';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'DownloadData',
  mixins: [loading],
  props: {
    entitySet: {
      type: String,
      required: true,
    },
    fileExtension: {
      type: String,
      default: 'xlsx',
    },
  },
  data() {
    return {
      mdiDownload,
    };
  },
  computed: {
    ...mapState('organizations', ['organization']),
    client() {
      return new Client({
        baseUrl: process.env.VUE_APP_APIURL,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        apiClient: 'axios',
        timeout: 30000,
        headers: {
          organizationid: this.organization.id,
        },
      });
    },
    downloadUrl() {
      return `${process.env.VUE_APP_APIURL}/odata/${this.entitySet}/download`;
    },
  },
  methods: {
    async download() {
      const { downloadUrl } = this;
      try {
        this.isLoading = true;
        console.debug(downloadUrl);
        const token = this.$auth.getToken();
        const response = await axios({
          url: downloadUrl,
          method: 'GET',
          responseType: 'blob', // important
          headers: {
            Authorization: `Bearer ${token.access_token}`,
            organizationid: this.organization.id,
          },
        });
        this.$log.debug('response', response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.entitySet}.${this.fileExtension}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
